.LandingPage-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.landingVideo {
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  object-fit: cover;
}

.landingPage-buttonRow {
  position: absolute;
  bottom: 10%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.landingPage-buttonRow > a {
  border-radius: 50%;
  margin: 0 100px;
}

.landingPage-buttonRow > a > button {
  background-color: var(--alma-pink);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  border: none;
  outline: none;
  text-align: center;
}

.landingPage-buttonRow > a > button:hover {
  filter: brightness(85%);
}

.landingPage-buttonRow > a:last-of-type > button {
  background-color: var(--alma-blue);
  color: white;
  font-size: 2rem;
}

.landingImages-text {
  font-size: 9rem;
}

@media screen and (max-width: 560px) {
  .landingImages-text {
    font-size: 7rem;
  }
}

@media screen and (max-width: 800px) {
  .LandingPage-container {
    display: flex;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  .landingVideo {
    /* min-height:calc(100vh - 170px); */
    object-fit: cover;
    min-height: 0;
    min-width: 0;
    margin: auto 0;
    /* transform-origin:50% 50%; */
    /* transform:scale(1) translateX(0%); */
  }
  .landingPage-buttonRow > a {
    margin: 10px;
  }

  .landingPage-buttonRow {
    bottom: 0;
  }
}
