.ThankYou-container {
  background-color: rgba(255, 255, 255, 0.8);
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ThankYou-container > p {
  text-align: center;
  font-size: 1.5rem;
  white-space: pre-wrap;
  margin: 0 10px;
}

