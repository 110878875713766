.selectedItem-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  min-height: calc(100vh - 121px);
}

.selectedItem-payloadRow {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  margin-right: 20px;
}

.selectedItem-infoCol {
  max-width: 50ch;
  font-family: alma-regular;
  grid-column: 2;
}
.selectedItem-infoCol > h1 {
  font-family: alma-bold;
}
.selectedItem-price {
  font-family: alma-bold;
}

.carousel-container > button {
  position: absolute;
  z-index: 1;
  pointer-events: all;
}

.carousel-container > button:nth-of-type(2) {
}

.carousel-container {
  pointer-events: none;
  min-height: 100%;
  grid-column: 1;
  grid-row: 1;
  position: relative;
}

.selectedItem-info {
  font-family: alma-regular;
}

.carousel-container > img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: auto;
  object-position: 0;
  transition: opacity 0.7s;
  z-index: 1;
  position: absolute;
  pointer-events: none;
}

.carouseldots-container {
  --carousel-dot-size: 13px;
  display: grid;
  height: var(--carousel-dot-size);
  position: absolute;
  z-index: 2;
  opacity: 0;
  padding: 6px;
  border-radius: 10px;
  grid-template-columns: repeat(
    var(--total-carousel-elements),
    var(--carousel-dot-size)
  );
  gap: var(--carousel-dot-size);
  transform: translate(-50%, -100%);
  animation: dot-fade 2s ease-in forwards;
}

@keyframes dot-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes dot-fade2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.carouseldots-dot-sel,
.carouseldots-dot {
  background-color: var(--alma-pink);
  border-radius: 50%;
}

.carouseldots-dot {
  background-color: transparent;
  border: solid var(--alma-pink) 1px;
}

@media screen and (max-width: 800px) {
  .selectedItem-container {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 0;
    height: auto;
    min-height: calc(100vh - 100px);
  }
  * {
    overflow: none;
  }

  .selectedItem-payloadRow {
    display: block;
    width: 100%;
    margin-left: 0px;
  }

  .carousel-container {
    pointer-events: all;
    width: 100%;
    min-height: 100px;
  }

  .carousel-container > img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: auto;
    object-position: 50%;
    transition: opacity 0.7s;
    z-index: 1;
    pointer-events: none;
  }
  .selectedItem-info {
    padding: 0 20px;
    padding-bottom: 20px;
  }

  .selectedItem-priceCart > * {
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;
  }
}
