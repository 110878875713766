.hamburgerMenu-container {
    list-style: none;
    font-size: 1.8rem;
    margin: 0;
    padding: 0;
    padding: 0 30px;
    width: 180px;
    border-right:var(--alma-pink) 1px solid;
}

.hamburgermanu-trancendent {
    width: 100%;
}

.instalink{
    text-decoration:none;
    color:black;
}

.hamburgerMenu-li {
    cursor: default;
    display: flex;
    padding-left: 7px;

    font-family:alma;
}

.hamburgerMenu-li-unchecked {
    cursor: pointer;
    padding-left: 7px;
    display: flex;
    font-family:alma;
}

.hamburgerMenu-li-unchecked:hover {
    font-weight: bold;
}
