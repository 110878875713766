.checkoutview-whole {
  background-color: white;
  width: 100%;

  min-height: calc(100vh - 100px);
}

.checkoutview-container {
  padding: 40px;
  /* background-color: white; */
  margin-right: 210px;
  max-width: 900px;
  /* height: calc(100vh - 250px); */
}

.checkoutview-table {
  /* overflow-y: auto; */
  flex: 3;
}

.checkoutview-table > div {
}

.checkoutview-table > header,
.checkoutview-table > footer,
.checkoutview-table > span {
  font-size: 1.3rem;
  text-align: left;
  border-spacing: 20px;
  display: flex;
}
.checkoutview-table > span {
  margin-top: 10px;
}

.checkoutview-table > header > p:nth-of-type(2),
.checkoutview-table > footer > p:nth-of-type(2),
.checkoutview-table > span > p:nth-of-type(1) {
  margin-left: auto;
  width: 110px;
  min-width: 110px;
}

.checkoutview-table > header,
.checkoutview-table > footer {
  font-weight: bold;
}

.checkoutview-table > footer {
  margin-top: 40px;
}

.checkoutview-form {
  margin-top: auto;
  padding: 40px;
}

.checkoutview-order {
  display: flex;
  margin-top: 20px;
}

.chekoutview-shiptext {
  white-space: pre-line;
  margin-left: 20px;
  max-width: 70ch;
}

@media screen and (max-width: 1053px) {
  .checkoutview-container {
    margin-right: 0px;
  }
}

@media screen and (max-width: 800px) {
  .checkoutview-container {
    margin-right: 0px;
  }
}

@media screen and (max-width: 550px) {
  .checkoutview-container {
    padding: 10px;
  }

  .checkoutview-order {
    flex-direction: column-reverse;
  }

  .chekoutview-shiptext {
    white-space: pre-line;
    margin-bottom: 20px;
    margin-left: 0;
  }
}
