.shopView-container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--minShopImgWidth), 1fr));
    column-gap: 10px;
    row-gap: 15px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 15px;
    box-sizing: border-box;
    min-height: calc(100vh - 100px);
    height: 100%;
}
@media screen and (max-width:1550px){
    .shopView-container{
        grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    }
}
@media screen and (max-width:900px){
    .shopView-container{
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media screen and (max-width:560px){
    .shopView-container{
        grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    }
}

@media screen and (max-width:440px){
    .shopView-container{
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }
}
