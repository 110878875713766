.uploadNewItem-container{
    padding: 10px 40px;
    max-height: 700px;
    overflow: auto;
}

.imageChooser-container{
    
    
}

.imageChooser-container>img{
    height:auto;
    width: 100px;
    
}

.localImage-container{
    display: flex;
    margin-top: 10px;
}

.localImage-container>img{
    /* height:auto; */
    width: auto;
    height: 200px;
    object-fit: contain;
}