.aboutView-container{
    background-color: rgb(255, 255, 255);
    padding:15px 10px 0 10px;
    min-height: calc(100vh - 115px);
    flex: 1 1 auto;
    display: flex;
}

.aboutView-image{
    flex: 1;
    margin: auto 50px;
    margin-top:10px;
    max-height: 80vh;
    max-width: 80vh;
    position: relative;
    padding:10px 0;
}



.aboutView-image>img{
    border-radius: 50%;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
    object-position: center;
    transition: opacity 0.7s;
    height: 100%;
    background-color: var(--alma-pink);
    
}


.aboutView-container>article{
    flex: 1;
    margin: auto 0;
    font-size: 1.5rem;
    line-height: 2.3rem;
    max-width: 50ch;
    white-space: pre-wrap;
    padding-bottom:10px;
    padding-top:10px;

    
}

@media screen and (max-width:1000px){
    .aboutView-image{
        flex: 2;
        
    }
 
}

@media screen and (max-width:800px){
    .aboutView-container{
        display: block;
    }
    .about-textPane{
        text-align:center;
    }
    .about-textPane>div>a>button{
        margin:auto;
    }
}
