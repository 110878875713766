.spinner-container {
    width: var(--loadIndicatorSize);
    height: var(--loadIndicatorSize);
}

.spinner-head {
    
    position: relative;
    animation-name: snurra;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: var(--animDurationLoadIndicator);
    transform-origin: 5px 5px;
}

.spinner-head > div {
    
    width: var(--loadIndicatorDotSize);
    height: var(--loadIndicatorDotSize);
    border-radius: 50%;
    position: absolute;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: var(--animDurationLoadIndicator);
}

.spinner-head > div:nth-of-type(1) {
    left: -10px;
    top: 0px;
    animation-name: byta1;
    background-color: var(--alma-blue);
}

.spinner-head > div:nth-of-type(2) {
    left: 0;
    top: 10px;
    animation-name: byta4;
    background-color: #9c0323;
}

.spinner-head > div:nth-of-type(3) {
    left: 10px;
    top: 0;
    animation-name: byta2;
    background-color: var(--alma-yellow);
}
.spinner-head > div:nth-of-type(4) {
    top: -10px;
    left: 0;
    animation-name: byta3;
    background-color: var(--alma-pink);
}

@keyframes snurra {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

@keyframes byta1 {
    0% {
        left: calc(var(--loadIndicatorSize) * -1);
    }
    33% {
        left: var(--loadIndicatorSize);
    }
    66% {
        left: var(--loadIndicatorSize);
    }
    100% {
        left: calc(var(--loadIndicatorSize) * -1);
    }
}

@keyframes byta2 {
    0% {
        left: var(--loadIndicatorSize);
    }
    33% {
        left: calc(var(--loadIndicatorSize) * -1);
    }
    66% {
        left: calc(var(--loadIndicatorSize) * -1);
    }
    100% {
        left: var(--loadIndicatorSize);
    }
}

@keyframes byta3 {
    0% {
        top: var(--loadIndicatorSize);
    }
    33% {
        top: var(--loadIndicatorSize);
    }
    66% {
        top: calc(var(--loadIndicatorSize) * -1);
    }
    100% {
        top: var(--loadIndicatorSize);
    }
}

@keyframes byta4 {
    0% {
        top: calc(var(--loadIndicatorSize) * -1);
    }
    33% {
        top: calc(var(--loadIndicatorSize) * -1);
    }
    66% {
        top: var(--loadIndicatorSize);
    }
    100% {
        top: calc(var(--loadIndicatorSize) * -1);
    }
}
