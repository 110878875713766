.application-container {
    background-image: url("../../resources/avalma-\ background\ -\ \ long\ svg.svg");
    background-size: cover;
    background-repeat: repeat;

    /* height: 100px; */
}


.application-offline {
    background-image: url("../../resources/avalma-\ background\ -\ \ long\ svg.svg");
    background-color: rgba(255,255,255,0.7);
    background-blend-mode: overlay;
    background-size: cover;
    display: flex;
}

.application-header {
    display: flex;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 3;
    min-height: 100px;
    flex: 0 1 auto;
    /* overflow: hidden; */
}

@keyframes headerAnim {
    0%{
        transform:translateY(-100%);
        opacity:0;
    }
    100%{
        transform:translateY(0px);
        opacity:1;
    }
}

.application-header > p {
    margin: auto;
    font-family:alma;
    font-weight: 900;
    font-size: 5rem;
    padding-left: 180px;
    /* animation:headerAnim 0.5s; */
}

.application-header > div {
    position: absolute;
    display: flex;
    /* bottom: 0; */
    right: 0px;
}
.application-header > div > button {
    margin-top: auto;
    margin-left: 10px;
}

.application-mainContainer {
    background-image: url("../../resources/avalma-\ background\ -\ \ long\ svg.svg");
    background-size: cover;
    width: 100%;
}

.application-main {
    margin-top: 100px;
    display: flex;
}

.application-mobileMenuContainer {
    position: absolute;
    background-color: white;
    z-index: 100;
}

@media screen and (max-width: 800px) {
    .application-mainContainer {
        margin-right: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
