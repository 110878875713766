.fileuploader-container {
    height: 130px;
}

.fileuploader-uploadArea {
    display: flex;
}



.fileuploader-dropZone-over, .fileuploader-dropZone {
    width: 300px;
    height: 120px;
    padding: 0;
    /* margin: auto; */
    margin-top: 10px;
    background-color: lightcyan;
    border: solid black 1px;
    display: flex;
    transition: all 0.3s;
    cursor: pointer;
}

.fileuploader-dropZone {
    padding: 0px;
    background-color: white;
}

.fileuploader-innerDropZone, .fileuploader-innerDropZone-over {
    border: lightgray 2px dashed;
    
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
}

.fileuploader-dropZone div {
    margin: auto;
}

.fileuploader-dropZone-over{
    padding: 15px;
    height: 90px;
    width: 270px;
}

.fileuploader-dropZone-over div {
    margin: auto;
    
}

.fileuploader-dropZone p, .fileuploader-dropZone-over p {
    text-align: center;
    margin: auto;
    padding: 0;
    font-style: italic;
    
}

.fileuploader-dropZone p:last-of-type, .fileuploader-dropZone-over p:last-of-type{
    font-weight: bold;
    font-style: normal;
    
}

.droppedfile-removeBtn{
    height: fit-content;
    border: none;
    background-color: white;
    outline: none;
    padding: 5px;
    margin: auto 0;
    cursor: pointer;
    border-radius: 50%;
}

.droppedfile-removeBtn:hover{
    background-color: lightgray;
}

.droppedfile-container{
    display: flex;
}

.droppedfile-container>div{
    margin-right: 20px;
}

.droppedfile-name{
    margin: auto;
}

.droppedfile-name-clickable{
    margin: auto;
    cursor: pointer;
    color: #2A35FF;
}

.droppedfile-name-clickable:hover{
    text-decoration: underline;
}