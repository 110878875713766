.App {
  text-align: center;
}

@font-face {
  src: url("./resources/Minimal.ttf");
  font-family: "alma";
  font-display: block;
}

@font-face {
  src: url("./resources/Montserrat-Regular.ttf");
  font-family: "alma-regular";
  font-display: block;
}
@font-face {
  src: url("./resources/Montserrat-Bold.ttf");
  font-family: "alma-bold";
  font-display: block;
}

* {
  font-family: "alma-regular", sans-serif;
}

p {
  margin: 0;
}

html {
  --alma-pink: #e690a6;
  --alma-blue: #4a767f;
  --alma-yellow: #d8c163;
  --alma-red: #9c0323;
}
