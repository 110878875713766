.contactView-container{
    background-color: rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    padding: 50px 0;
    min-height: calc(100vh - 100px);

}

.contactView-form{
    width: 70%;
    margin: auto;
    transform: translateX(-100px);
}

.contactView-form>h1{
    text-align: center;
    font-size: 3rem;
    margin: 0;
}
@media screen and (max-width:1000px){
    .contactView-form{
        transform:none;
    }
}

@media screen and (max-width:500px){
    .contactView-form{
        width: 90%;
    }
}
