

.NotFoundItem-container {
    min-height: calc(100vh - 100px);
    background-image: url("../../resources/avalma-\ background\ -\ \ long\ svg.svg");
    background-color: rgba(255,255,255,0.7);
    background-blend-mode: overlay;
    background-size: cover;
    display: flex;
}

.NotFoundItem-container>p{
    margin:auto;
    font-size:1.8rem;
    white-space:pre-wrap;
    text-align:center;
    line-height:2.8rem;
    transform:translateX(-100px);
}

@media screen and (max-width: 800px){
    .NotFoundItem-container>p{
        transform:none;
        padding:20px;
        font-size:1.5rem;
    }
}
