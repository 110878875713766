.absdrop-container{
    z-index: 5;
    position: absolute;
    right: 0;
    top: 20px;
    margin-left: 50px;
    left: 100px;
    max-width: 350px;
}

.absdrop-expandable{
    
    background-color: white;
    /* border: solid black 1px; */
    max-height: 20px;
    overflow: hidden;
    transition: max-height 0.5s;
    box-shadow: 0px 0px 8px #888888;
    cursor: pointer;
    top: 5px;
    z-index: 10;
    padding: 0 5px 3px 5px;
}

.absdrop-expandable:hover{
    background-color: #EBEBEB;
}