

.NotFoundPage-container {
    background-image: url("../../resources/avalma-\ background\ -\ \ long\ svg.svg");
    background-color: rgba(255,255,255,0.7);
    background-blend-mode: overlay;
    background-size: cover;
    display: flex;
}

.NotFoundPage-container>h1{
    margin:auto;
    white-space:pre-wrap;
    text-align:center;
    padding:20px;
}
