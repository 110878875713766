.voucherTable-container {
  padding: 10px 10px;
  max-height: 500px;
  overflow-x: auto;
  overflow-y: auto;
}

.voucherTable-line {
  display: flex;
  white-space: nowrap;
  margin-bottom: 10px;
  background-color: #ebebeb;
  padding: 10px 0px;
  width: 230%;
}

.voucherTable-header {
  font-weight: bold;
  background-color: unset;
  padding: 0;
  margin-bottom: 2px;
}

.voucherTable-header > div {
  cursor: pointer;
  display: flex;
}

.voucherTable-header > div > p {
  margin-right: 10px;
}

.voucherTable-header > div:hover {
  background-color: #ebebeb;
}

.voucherTable-header > div:hover:first-child {
  background-color: white;
}
.voucherTable-header > div:first-child {
  cursor: unset;
  display: flex;
}

.voucherTable-line > div:nth-child(1) {
  flex: 0 0 50px;
}

.voucherTable-line > div:nth-child(2) {
  flex: 0 0 100px;
}
.voucherTable-line > div:nth-child(3) {
  flex: 0 0 100px;
}
.voucherTable-line > div:nth-child(4) {
  flex: 0 0 150px;
}
.voucherTable-line > div:nth-child(5) {
  flex: 0 0 150px;
}
.voucherTable-line > div:nth-child(6) {
  padding-right: 10px;
}
