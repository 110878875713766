.input-container-input {
  font-size: 1rem;
  font-weight: 600;
  border: 1px rgb(201, 201, 201) solid;
  min-width: 240px;
  box-sizing: border-box;
}

.input-container-input:hover {
  background-color: rgb(238, 238, 238);
  border: 1px rgb(170, 170, 170) solid;
}

.input-container-input:focus {
  outline: none;
  background-color: #e0cf8c;
  border: var(--alma-yellow) 1px solid;
}

.input-container > p {
  margin: 0;
  font-size: 1rem;
}

.input-container {
  margin-top: 10px;
}
