.snackbar-content {
    min-width: 300px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: rgba(20,20,20,0.7); /* Black background color */
    color: #fff; /* White text color */
    
    
    padding: 15px; /* Padding */
    white-space: pre-wrap;
    position: fixed; /* Sit on top of the screen */
    z-index: 60; /* Add a z-index if needed */
    right: 160px; /* Center the snackbar */
    top: var(--snackbar-top-pos); /* 30px from the bottom */
    animation: fadein 0.5s, fadeout 0.5s var(--persistTimeSnackbar);
    display: flex;
    /* box-shadow:0px 0px 10px 2px #888888; */
}

.snackbar-content div:first-of-type{
    
    /* background-color: white; */
    /* padding: 2px; */
    margin: auto 5px;
    display: flex;
    /* margin-right: 5px; */
}

.snackbar-content p{
    margin: auto auto auto 20px;
    font-size: 1.3rem;
}

.snackbar-content div:last-of-type{
    margin: auto 0 auto auto;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    padding: 5px;
    
}

.snackbar-content div:last-of-type:hover{
    opacity: 0.5;
}

@media screen and (max-width:800px){
    .snackbar-content {
        width: 300px; /* Set a default minimum width */
        left: calc(50% - 175px); 
        right: unset;
        margin-left: auto;
        margin-right: auto;
    }
}

@keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: var(--snackbar-top-pos); opacity: 1;}
  }

  
  @keyframes fadeout {
    from {top: var(--snackbar-top-pos); opacity: 1;}
    to {top: 0; opacity: 0;}
  }

  @media screen and (max-width:800px){
    .snackbar-content p{
        margin: auto auto auto 20px;
        font-size: 1.2rem;
    }

    .snackbar-content {
        min-width: 300px; /* Set a default minimum width */

        right: 160px; /* Center the snackbar */
        margin-left: 20px;
        padding: 5px; /* Padding */

    }
  }
