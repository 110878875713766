.shopitem-container {
    width: 100%;
    position: relative;
    /* margin: auto; */
    height: fit-content;
}

.shopitem-info, .shopitem-info-mobile{
    position: absolute;
    bottom: 10px;
    font-weight: bold;
    color:var(--alma-pink);
    font-size: 1.3rem;
    transition: color 0.3s ease, background-color 0.3s ease;
    left: 50%;
    width:100%;
    pointer-events: none;
    white-space: pre;
    text-align:center;
    transform: translateX(-50%);
}

.shopitem-info{
    background-color:white;
    bottom:4px;
    padding:10px 0;
}

.shopitem-info-mobile{
    position:static;
    color:black;
    transform:none;
    margin-bottom:10px;
}

.shopitem-container > h5, .shopitem-container>h4{
    position: absolute;
    top: 0;
    margin: 0;
    max-width:100%;
    padding: 3px 10px;
    white-space: pre-wrap;
    color: var(--alma-pink);
    font-size: 1.3rem;
    background-color: white;
    overflow-wrap:break-word;
    left: 0;
}

.shopitem-container>h4{
    background-color:transparent;
    color:white;
    font-weight:lighter;
    font-size:1.5rem;
}

.shopitem-container > button {
    position: absolute;
    top: 0;
    right: 0;
}

.shopitem-img {
    height: auto;
    margin: auto;
    cursor: pointer;
    width: 100%;
    transition: 0.3s filter ease,opacity 0.7s ease;
    
}

.shopitem-img:hover {
    filter: brightness(var(--hoverBrightness));
}

.shopitem-container-link {
    display: flex;
    text-decoration: none;
}
