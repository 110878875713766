.modal-display{
   
    position: fixed;
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    display: flex;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
    animation-name: fader;
    animation-duration: 0.5s;
}


@keyframes fader{
    from{background-color: rgba(0,0,0,0);}
    to{background-color: rgba(0,0,0,0.3);}
}

.modal-header{
    display: flex;
    cursor: grab;
}

.modal-header h4{
    margin: 5px;
}

.modal-header>button{
    margin-left: auto;
    border: none;
    background-color: white;
    outline: none;
    padding: 5px;
    max-height: 35px;
    cursor: pointer;
    border-radius: 50%;
}

.modal-header>button:hover{
    background-color: lightgray;
}

.modal-content {
    /* margin:auto auto auto auto;  */
    position: absolute;
    left: 25%;
    z-index: 100;
    top:25%;
    border-top: solid var(--alma-yellow) 3px;
    background-color: white;
    box-shadow: 0px 4px 5px 3px #88888888;
    width: var(--modalwidth); /* Could be more or less, depending on screen size */
    
    /* position: relative; */
  }

.modal-footer{
    display: flex;
    padding: 5px;
    flex-direction: row-reverse;
    cursor: unset;
    /* position: absolute; */
    /* bottom: 0; */
}

.modal-footer>button{
    margin-left: 10px;
}

@media screen and (max-width:900px){
    .modal-content{
        width: 95%;
        left: 2.5%;
    }
}