.button,
.button-small,
.button-pink,
.button-yellow,
.button-round {
  font-size: large;
  /* font-weight: bold; */
  color: white;
  background-color: var(--alma-blue);
  border: none;
  padding: 5px 30px;
  height: fit-content;
  font-size: 1.2rem;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  font-family: alma-regular;
}

.button-pink {
  background-color: var(--alma-pink);
}

.button-round {
  border-radius: 50%;
  white-space: pre-line;
  text-align: center;
  line-height: 1.8rem;
  height: 6rem;
  width: 6rem;
  padding: 0;
  outline: 0;
}

.button-pink:hover:enabled {
  filter: brightness(85%);
}

.button-pink:disabled,
.button-small:disabled {
  cursor: default;
  background-color: #aeb0b1;
}

.button-yellow {
  background-color: var(--alma-yellow);
  max-width: 150px;
}

.button-yellow:hover:enabled {
  filter: brightness(85%);
}

.button-yellow:disabled {
  cursor: default;
  background-color: #aeb0b1;
}

.button-small {
  font-size: 1rem;
}

.button:hover:enabled,
.button-small:hover:enabled,
.button-round:hover:enabled {
  background-color: #395c63;
}

.button:disabled {
  cursor: default;
  background-color: #aeb0b1;
}

.button-link {
  /* font-weight: bold; */
  color: var(--alma-blue);
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  display: block;
  text-align: left;
  width: fit-content;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.button-link::first-letter {
  text-transform: capitalize;
}

.button-link:hover {
  color: #9c0323;
}

.button-icon,
.button-icon-noShadow {
  border: none;
  cursor: pointer;
  display: block;
  padding: 0;
  background-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.button-icon:active,
.button-icon-noShadow:active {
  /* background-color:red */
}

.button-icon > img {
}

#Layer_1:hover {
  fill: var(--iconHoverColor);
  stroke: var(--iconHoverColor);
}

.button-icon:hover,
.button-icon:active {
  fill: #395a61;
  /* background-color: #aaaaaa66; */
}

.button-icon-noShadow:hover {
  fill: #395a61;
}
