.shipinfo-container {
    background-color: rgba(255, 255, 255, 0.6);
    min-height: calc(100vh - 150px);
    /* padding: 10px 0px; */
    padding-bottom: 50px;
    text-align: center;
    /* flex: 1 1 auto; */
}

.shipinfo-container > div {
    width: 60%;
    margin: auto;
    transform: translateX(-100px);
}

.shipinfo-container > div > h1 {
    font-family:alma-regular;
    margin: 0;
    padding-top: 1.3rem;
    color: black;
}

.shipinfo-container > div > p {
    text-align: justify;
    font-family: alma-regular;
    line-height: 2.0rem;
    color: #162427;
    white-space: pre-wrap;
}

@media screen and (max-width: 800px) {
    .shipinfo-container > div {
        width: 90%;
        transform: none;
    }
    .shipinfo-container {
        height: auto;
        
    }

}
