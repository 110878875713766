.menudrawer-container > {
    position: relative;
}

.menudrawer-menu {
    transition: all 0.4s;
    height: 100%;
    z-index: 2;
    right: 0;
    position: fixed;
    background-color: white;
    background-image: url("../../resources/avalma-\ background\ -\ \ long\ svg.svg");
    background-repeat: space;
    background-size: cover;
}

.menudrawer-child{
    width: 100%;
    height: 100%;
    z-index: 0;
    
    display: flex;
    flex-direction: column;
    transition: opacity 0.4s;
}

.menudrawer-hider{
    background-color: white;
    height: 100%;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1;
    transition: opacity 0.4s;
    pointer-events: none;
}



.menudrawer-overlay{
    height: calc(100% - 96px);
    margin-top: 100px;
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
}

.menudrawer-hamburgerMenu-container{
    list-style: none;
    font-size: 6vh;
    padding: 0;
    flex: 1 1 auto;
    overflow: auto;
}

.menudrawer-hamburgerMenu-li{
    cursor: default;
    display: flex;
}


.menudrawer-hamburgerMenu-li-unchecked{
    cursor: pointer;
    display: flex;
}

.menudrawer-hamburgerMenu-li-unchecked:hover{
    font-weight: bold;
}

@media screen and (max-height:547px){
    .menudrawer-hamburgerMenu-container{
        font-size: 5.5vh;
    }

    .menudrawer-overlay{
        height: calc(100% - 66px);
    }
}
