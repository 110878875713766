.progressBar-container{

}

.progressBar-bar{
    height: 20px;
    width: 100%;
    background-color: aliceblue;
    padding: 3px;
}

.progressBar-bar>div{
    background-color: #9c0323;
    height: 20px;
}

.uploadprogress-container{
    display: flex;
    /* overflow: hidden; */
    padding: 10px;
    position: relative;
}

.uploadprogress-container>p{
    margin: auto 5px 0 0;
    white-space: nowrap;
    /* overflow: hidden; */
}

.uploadprogress-cancelBtn{
    cursor: pointer;
    display: flex;
    margin: auto 0;
    position: absolute;
    right: 10px;
    top: 35%;
}

.uploadprogress-cancelBtn:hover{
    
    color: red;
}

.uploadprogress-loadingIndicator{
    width: 100%;

}